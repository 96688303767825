<template>
  <div class="is-flex is-column">

    <article>
      <div class="is-flex is-row has-align-items-start">
        <div class="has-width-three pr-10 has-text-left">
          <h2 class="mb-20">Värderings&shy;analys</h2>
          <p>Vår värdering baserad på historiska presetationer är ett utmärkt sätt att skapa sig en uppfattning kring vad en extern intressent anser att ett företag kan vara värt.</p>
          <p>Den metod som används baseras på bolagets historiska kassaflöden och tillväxt för att därifrån prognosticera framtiden.</p>
          <p>
            För att det skall vara möjligt att göra en värdering krävs bl.a. positiva historiska kassaflöden och att det är en viss jämnhet i de historiska prestationerna.
          </p>

          <h3>Användning</h3>
          <p>Vår kassaflödesvärdering är ett lite mer formellt sätt att presentera en värdering av bolaget på och passar utmärkt för att visa upp för såväl internt bruk vid som inför externa intressenter såsom banken eller investerare.</p>

          <h3>Leveransformat</h3>
          <p>Analysen levereras som en åttasidig PDF-rapport.</p>

          <h3>Pris</h3>
          <p>4 900 kr (+ moms)</p>
        </div>

        <div class="has-width-one info-box is-hidden-on-mobile">
          <div class="fontsize-3 has-fontweight-600">Exempel</div>
          <div class="pdf">
            <a href="../exempel/Rapport_Vardering.pdf" target="_blank" class="pdf">Rapport (PDF)</a>
          </div>
        </div>
      </div>

    </article>

    <FooterOrder reportType="vardering"/>
    
  </div>
</template>

<script>
  import FooterOrder from "@/components/FooterOrder.vue";

	export default {
		name: "AnalysesCashflow",
		components: {
			FooterOrder,
    },
    mounted () {
      window.scrollTo(0, 0)
    }
	};

</script>

<style lang="scss" scoped>

article {
  margin-bottom: 15vh;

}
</style>