<template>
  <div class="is-flex is-column">

    <article class="is-flex is-row has-align-items-start">
        <div class="has-text-left">
        <h2 class="mb-20">Kontakt</h2>
          <p>Analyspartner är en tjänst som är skapad av Bolagskompassen AB.</p>
          <p>
            Med ena benet i finansiell analys och det andra i innovativa IT-lösningar grundades Bolagskompassen 2013 för att visualisera och göra ekonomisk företagsanalys lättebegriplig för alla.
          </p>
          <p>
            På Bolagskompassen brinner vi för att skapa effektiva lösningar för att analysera och presentera ekonomisk information för såväl rådgivare som företagare. Med passion och precision utvecklar vi skalbara webblösningar för både egen och andras användning.
          </p>
          <p>
          Bolagskompassen ägs och drivs av Kristoffer Norén och Martin Woxneryd.
          </p>
          <p>Läs mer på <a href="https://bolagskompassen.se" target="_blank">bolagskompassen.se</a> eller <a href="mailto:info@bolagskompassen.se">maila oss</a>.</p>
        </div>
      <div class="has-text-left info-box">
        <div class="has-fontweight-600">Bolagskompassen AB</div>
        <div class="mt-05">Org.nr. 556934-2024</div>
        <div class="mt-05">Storgatan 35</div>
        <div class="mt-05">411 38 Göteborg</div>
      </div>
    </article>
        
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  }

}
</script>

<style lang="scss" scoped>

</style>