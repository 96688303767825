import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Analyses from '../views/Analyses.vue'
import AnalysesCashflow from '../views/AnalysesCashflow.vue'
import AnalysesValuation from '../views/AnalysesValuation.vue'
import AnalysesYearend from '../views/AnalysesYearend.vue'
import AnalysesWebreport from '../views/AnalysesWebreport.vue'
import Webreport from '../views/Webreport.vue'
import How from '../views/How.vue'
import Blog from '../views/Blog.vue'
import Order from '../views/Order.vue'
import OrderDone from '../views/OrderDone.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/analyser',
      name: 'Analyses',
      component: Analyses
    },
    {
      path: '/analyser/kassaflode',
      name: 'AnalysesCashflow',
      component: AnalysesCashflow
    },
    {
      path: '/analyser/vardering',
      name: 'AnalysesValuation',
      component: AnalysesValuation
    },
    {
      path: '/analyser/bokslut',
      name: 'AnalysesYearend',
      component: AnalysesYearend
    },
    {
      path: '/analyser/webbrapport',
      name: 'AnalysesWebreport',
      component: AnalysesWebreport
    },
    {
      path: '/webbrapport',
      name: 'Webreport',
      component: Webreport
    },
    {
      path: '/kontakt',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/blogg',
      name: 'Blog',
      component: Blog
    },
    {
      path: '/bestall/:order',
      name: 'Order',
      component: Order
    },
    {
      path: '/bestallt',
      name: 'OrderDone',
      component: OrderDone
    },
    {
      path: '/sa-funkar-det',
      name: 'How',
      component: How
    },
  ]
})

export default router
