<template>
  <div class="home">
    <section class="has-bg-white is-flex is-centered-in-middle p-10" id="landing">
      <div>
        <h1>All rådgivning börjar med en bra analys</h1>
        <div class="fontsize-3 my-20">Vi utför prisvärda ekonomiska analyser för företag med en omsättning mellan 5 och 100 mkr</div>
      
        <!-- <router-link to="/webbrapport" class="link has-color-white pt-20">
          <div class="button button-continue link">
              Gratis webbrapport till siste maj 2022
          </div>
        </router-link> -->
        </div>
    </section>

    <section class="has-bg-primary p-20 is-flex is-row">
      <div class="is-centered-in-middle">
        <h2 class="has-color-light my-20">Webbrapporter</h2>
        <p class="has-color-light webreport has-text-left">En webbrapport är en företagsanalys som på ett pedagogiskt sätt går igenom ett bolag och dess ekonomiska situation. Analysen är skapad för företagare utan krav på djupare ekonomiska kunskaper och levereras i form av en interaktiv webbsida.</p>
        <a href="https://demo.analyscenter.se/webreport/8d0cde89-ea2c-4608-a117-a71aff2ef73c" target="_blank" class="button_example">Se exempel</a>
      </div>

      <!-- <div class="is-flex is-row">
        <div class="has-width-one p-20">
          <h3 class="has-text-left has-color-white">Interaktiv webbsida</h3>
          <p class="has-text-left has-color-light">Rapporten levereras som en inter&shy;aktiv webb&shy;sida där det är enkelt att för&shy;djupa sig i de olika diagrammen och dela analysen vidare om man så vill.</p>
        </div>

        <div class="has-width-one p-20">
          <h3 class="has-text-left has-color-white">Bokslut eller SIE</h3>
          <p class="has-text-left has-color-light">Vi kan skapa analyserna antingen på historisk data via officiella års&shy;redovisningar eller på SIE-filer direkt från ert bokförings&shy;program.</p>
        </div>

        <div class="has-width-one p-20">
          <h3 class="has-text-left has-color-white">Prisvärt</h3>
          <p class="has-text-left has-color-light">Genom att bygga en dynamisk mall där alla beräkningar görs kan vår analytiker fokusera på själva analysen i webb&shy;rapporten och därmed skapa genom&shy;arbetade rapporter till ett oslagbart pris.</p>
          <p class="has-text-left has-color-contrast has-fontweight-600">Webbrapporterna kostar endast 3.900 kr (+ moms)</p>
        </div>

        <div class="has-width-one p-20">
          <h3 class="has-text-left has-color-white">Snabb leverans</h3>
          <p class="has-text-left has-color-light">Vi genomför analysen och skapar vanligtvis webb&shy;rapporten inom två arbets&shy;dagar från beställning.</p>
        </div>
      </div> -->
    </section>

    <div id="cashflow" class="p-20">
      <div class="is-flex is-row pb-20">
        <div class="webreport">
          <h2 class="has-text-left">Kassa&shy;flödes&shy;genomgång</h2>
          <div class="pt-10 has-color-black has-text-left">
            <p>Många förknippar en kassa&shy;flödes&shy;analys med den svår&shy;tydda tabell som större bolag måste inkludera i sin års&shy;redovisning.</p>
            <p>Men en kassaflödes&shy;analys kan också ligga till grund för en <span class="has-fontweight-600">djup&shy;lodande analys som skapar större förståelse för hur de olika delarna i verk&shy;samheten påverkar bolagets likvida medel</span>. Denna analys kan därefter vara ett utmärkt under&shy;lag för att planera och genom&shy;föra åtgärder för att förbättra verksamheten.</p>
            <p>Med den här kassaflödes&shy;genomgången, som presenteras i form av en webb&shy;rapport, tar vi läsaren genom hela processen från att beräkna de totala kassa&shy;flödena till att analysera hur de ingående delarna påverkat bolagets ekonomiska situation över tid.</p> 
          </div>
        </div>
      </div>

      <a href="https://demo.analyscenter.se/webreport/8d0cde89-ea2c-4608-a117-a71aff2ef73c" target="_blank" class="button_example_small">Se exempel</a>


      <router-link to="/bestall/webbrapport" class="link has-color-white">
        <div class="button button-continue link fontsize-2">
            Beställ webbrapport
        </div>
      </router-link>

    </div>


    <section class="has-bg-white p-20">
      <h2>Fördelar med webbrapporter</h2>
      <div class="is-flex mt-20 is-row">
        <div class="p-10 has-width-one">
          <div class="big-size">1.</div>
          <div class="fontsize-3 has-fontweight-600">Interaktiv webbsida</div>
          <p>Rapporten levereras som en inter&shy;aktiv webb&shy;sida där det är enkelt att för&shy;djupa sig i de olika diagrammen och dela analysen vidare om man så vill.</p>
        </div>
        <div class="p-10 has-width-one">
          <div class="big-size">2.</div>
          <div class="fontsize-3 has-fontweight-600">Bokslut eller SIE</div>
          <p>Vi kan skapa analyserna antingen på historisk data via officiella års&shy;redovisningar eller på SIE-filer direkt från ert bokförings&shy;program.</p>
        </div>
        <div class="p-10 has-width-one">
          <div class="big-size">3.</div>
          <div class="fontsize-3 has-fontweight-600">Prisvärt</div>
          <p>Genom att bygga en dynamisk rapport&shy;mall där alla beräkningar görs kan vår analytiker fokusera på själva analysen och därmed skapa genom&shy;arbetade rapporter till ett oslagbart pris.</p>
          <p class="has-fontweight-600">Webbrapporterna kostar endast 3.900 kr (+ moms)</p>
        </div>
        <div class="p-10 has-width-one">
          <div class="big-size">4.</div>
          <div class="fontsize-3 has-fontweight-600">Snabb leverans</div>
          <p>Vi genomför analysen och skapar vanligtvis webb&shy;rapporten inom två arbets&shy;dagar från beställning.</p>
        </div>
      </div>

      <!-- <router-link to="/sa-funkar-det" class="link has-color-white">
        <div id="continue-how" class="button button-continue link">
            Läs mer om analysprocessen
        </div>
      </router-link> -->

    </section>


    <!-- <section class="has-bg-lightgrey p-20">
      <h2 class="has-color-darkgrey">Våra webbrapporter</h2>

      <div class="is-flex has-space-evenly mt-10 is-row">
        <CardAnalyses reportType="kassaflode" class="link has-color-darkgrey"/>
      </div>

      <router-link to="/analyser" class="link has-color-white">
        <div class="button button-continue link">
            Se alla analyser
        </div>
      </router-link>
    </section> -->

    <!-- <section class="has-bg-white p-20">
      <h2>Så går det till</h2>
      <div class="is-flex mt-20 is-row">
        <div class="p-10 has-width-one">
          <div class="big-size">1.</div>
          <div class="fontsize-3 has-fontweight-600">Du väljer analys</div>
          <p>Vi har tagit fram ett antal standardiserade ekonomiska analyser vars innehåll ger svar på frågor som de flesta företagare har behov av att ha kontroll på för att utveckla sin verksamhet.</p>
        </div>
        <div class="p-10 has-width-one">
          <div class="big-size">2.</div>
          <div class="fontsize-3 has-fontweight-600">Programmet kontrollerar</div>
          <p>Vi stoppar in all relevant data (antingen baserat på officiell bokslutsdata eller en SIE-fil från affärssystemet) i vår egenutvecklade programvara för att kontrollera att den valda analysen är lämpligt för bolaget.</p>
          <p>Om analysen inte är lämplig på det aktuella bolaget så avbryter vi och då sker naturligtvis ingen debitering.</p>
        </div>
        <div class="p-10 has-width-one">
          <div class="big-size">3.</div>
          <div class="fontsize-3 has-fontweight-600">Vi analyserar</div>
          <p>När bolaget är kontrollerat så går vår erfarna analytiker igenom alla beräkningarna, analyserar resultatet och genomför eventuella justeringar.</p>
          <p></p>
        </div>
        <div class="p-10 has-width-one">
          <div class="big-size">4.</div>
          <div class="fontsize-3 has-fontweight-600">Leverans inom två dagar</div>
          <p>Inom två arbetsdagar levereras analysen i det format som du önskar. Vi erbjuder såväl mer formella rapporter som lättläst infografik och det finns även möjlighet att inkludera en inspelad presentation.</p>
        </div>
      </div>

      <router-link to="/sa-funkar-det" class="link has-color-white">
        <div id="continue-how" class="button button-continue link">
            Läs mer om analysprocessen
        </div>
      </router-link>

    </section> -->

    <section class="has-bg-lightgrey p-20">
      <h2 class="has-color-darkgrey">Våra PDF-analyser</h2>
      <p>Förutom webbrapporter säljer vi även mer traditionella analyser som levereras i PDF-format.</p>

      <div class="is-flex has-space-evenly mt-10 is-row">
        <CardAnalyses reportType="bokslut" class="link has-color-darkgrey"/>
        <CardAnalyses reportType="kassaflode" class="link has-color-darkgrey"/>
        <CardAnalyses reportType="vardering" class="link has-color-darkgrey"/>
      </div>

      <router-link to="/analyser" class="link has-color-white">
        <div class="button button-continue link">
            Se alla analyser
        </div>
      </router-link>
    </section>


    <section class="has-bg-light is-hidden">
      Blog
    </section>

    <footer class="has-bg-dark has-color-light is-flex is-row p-20 gap-20">
      <div class="has-width-one">
      </div>
      <div class="has-width-one">
        <h5 class="mb-05">Om oss</h5>
        <p class="fontsize-5">Analyspartner är en tjänst som drivs av Bolagskompassen AB med org.nr. 556934-2024. Bolagskompassen transformerar företags&shy;rådgivning genom att utveckla finansiell programvara för revisions- och rådgivnings&shy;branschen.</p>
      </div>
      <div class="has-width-one">
      </div>
    </footer>

  </div>
</template>

<script>
  import CardAnalyses from "@/components/CardAnalyses.vue";

	export default {
		name: "Home",
		components: {
			CardAnalyses,
    },
    mounted () {
      window.scrollTo(0, 0)
    }
	};

</script>

<style lang="scss" scoped>

section {
  min-height: calc(100vh / 3 * 2)
  
}

.big-size {
  font-size: 6rem;
  font-weight: 800;
  color: $lightgrey;
}

.button-continue {
  border: 1px solid $darkgrey;
  margin: 2rem auto;
  width: 35vw;
  background: $contrast;
  color: $darkgrey;
  font-weight: 600;

  &:hover {
    background: lighten($contrast, 7.5%);
    border-color: $black;
    color: $black;
  }
}

.button-beta {
  border: 1px solid $darkgrey;
  // margin: 2rem auto;
  width: 35vw;
  background: $contrast;
  color: $darkgrey;
  font-weight: 600;

  &:hover {
    background: lighten($contrast, 7.5%);
    border-color: $black;
    color: $black;
  }
}

.webreport {
  width: 50%;
  border-left: 0.2rem solid $contrast;
  padding: 1rem;
  margin: auto;
}

.button_example {
  display: block;
  background: $secondary;
  font-weight: 600;
  padding: 1rem;
  margin: 2rem auto;
  width: 35vw;
  border-radius: 0.2rem;
  border: 1px solid $dark;
  color: $light;
  text-decoration: none;
  transition: 0.5s;
  &:hover {
    border-color: $blackblack;
    box-shadow: 0 0 1rem 0 $lightgrey;
    color: $white;
    background: lighten($secondary, 7.5%);
  }
}

.button_example_small {
  display: block;
  background: $secondary;
  font-weight: 600;
  padding: 1rem;
  margin: auto;
  width: 15vw;
  border-radius: 0.2rem;
  border: 1px solid $dark;
  color: $light;
  text-decoration: none;
  transition: 0.5s;
  &:hover {
    border-color: $blackblack;
    box-shadow: 0 0 1rem 0 $lightgrey;
    color: $white;
    background: lighten($secondary, 7.5%);
  }
}

#continue-how {
  background-color: $primary;
  font-weight: 400;
  color: $light;
  &:hover {
    background-color: $secondary;
    color: $white;
  }
}

#landing {
  background-color: $primary;
  background-image: url(../assets/coding.jpg);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  background-attachment: fixed;
color: $light;
}

#cashflow {
  background-color: $lightgrey;
  background-image: url(../assets/coding.jpg);
  background-size: cover;
  background-position: center;
  background-blend-mode: screen;
  background-attachment: fixed;
  color: $light;
}

@media screen and (max-width: 1200px) {
  .is-row {
    flex-direction: column;
  }
  
  .button-continue {
    width: 80vw;
  }

  .has-space-evenly {
    align-items: center;
    justify-content: center;
  }

  .webreport {
    width: 100%;
  }
  .button_example,
  .button_example_small {
    width: 90%;
  }

}


</style>