<template>
  <div class="analyses p-20 has-bg-lightgrey">
    <h2>Våra analyser</h2>
    <div class="is-flex is-column has-align-items-center">
      <ItemAnalyses reportType="webbrapport" class="link has-color-darkgrey"/>
      <h3 class="pt-20">PDF-rapporter</h3>
      <ItemAnalyses reportType="bokslut" class="link has-color-darkgrey"/>
      <ItemAnalyses reportType="kassaflode" class="link has-color-darkgrey"/>
      <ItemAnalyses reportType="vardering" class="link has-color-darkgrey"/>
    </div>

  </div>
</template>
 
<script>
  import ItemAnalyses from "@/components/ItemAnalyses.vue";

	export default {
		name: "Analyses",
		components: {
			ItemAnalyses,
    },
    mounted () {
      window.scrollTo(0, 0)
    }
	};

</script>
