<template>
  <router-link :to="{ path: `/analyser/${reportType}` }">
    <div class="card p-10">
      <h3 class="mt-10">{{ title(reportType) }}</h3>
      <p class="mt-20">{{ content(reportType)}}</p>
      <div class="read-more">Läs mer</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ItemAnalyses",
  props: ["reportType"],

  methods: {
    title(reportType) {
      switch (reportType) {
        case 'kassaflode':
          return 'Kassaflödesanalys';
        case 'vardering':
          return 'Värderingsanalys';
        case 'bokslut':
          return 'Bokslutsanalys';
        case 'webbrapport':
          return 'Webbrapport';
      }
    },
    content(reportType) {
      switch (reportType) {
        case 'kassaflode':
          return 'Genom en kassaflödesanalys besvaras frågan "vart tar pengarna vägen?". Analysen delar upp de totala kassaflödena i fyra olika kategorier vilket i sin tur skapar möjlighet att fokusera kraften på de delar som främst förbättrar likviditeten i bolaget.';
        case 'vardering':
          return 'En värderingsanalys beräknar ett rimligt bolagsvärde baserat på bolagets historiska presentationer. Analysen kan såväl fungera som ett underlag för att driva bolaget i rätt riktning som ett bevis på att bolaget utvecklas åt rätt håll.';
        case 'bokslut':
          return 'I bokslutsanalysen gås bolagets resultat- och balansräkning stegvis igenom. Analysen innehåller även vår populära bolagskompass som genomm nyckeltalsindexering visuliserar resultat- och balansräkningen på ett pedagogiskt sätt.';
        case 'webbrapport':
          return 'Vår webbrapport utgår från kassaflödet i ett bolag och går på ett pedagogiskt sätt igenom och analyserar bolaget med utgångspunkt från detta. Analysen riktar sig till företagare och dess rådgivare för internt bruk med en strävan att förbättra bolaget.';
      }
    }
  }
}



</script>

<style lang="scss" scoped>


.card {
  position: relative;
  margin: 1rem;
  width: clamp(600px, 50vw, 90vw);
  min-height: 15rem;
  border: 1px solid $grey;
  border-radius: 0.2rem;
  transition: 0.5s;
  background-color: $light;
  color: $darkgrey;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 1rem 0 $darkgrey;
    border-color: $darkgrey;
    cursor: pointer;
    background-color: $white;
    color: $black;
    .read-more {
      background: $secondary;
      color: $white;
    }
  }
} 

.read-more {
  position: absolute;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $primary;
  transition: 0.5s;
  color: $light;
}

@media screen and (max-width: 1000px) {

  .card {
    width: 90vw;
  }

  

}



</style>