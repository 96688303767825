<template>
  <router-link :to="{ path: `/bestall/${reportType}` }">

    <div id="test" class=""> 

      <div class="p-15 fontsize-3">
        Beställ här
      </div>

    </div>
  </router-link>
</template>

<script>
export default {
  name: "FooterOrder",
  props: ["reportType"]
  }

</script>

<style lang="scss" scoped>

  #test {
    position: fixed;
    bottom: 0;
    min-height: 10vh;
    width: 100vw;
    background-color: $secondary;
    color: $light;
    font-weight: 600;
    transition: 0.5s;
    box-shadow: 0 0 1rem 0 $grey;

    &:hover {
      cursor: pointer;
      background-color: lighten($secondary, 7.5%);
 //     background-color: $contrast;
      color: $white;
 //     color: $black;
      box-shadow: 0 0 1rem 0 $darkgrey;
    }
  }

</style>