<template>
  <div class="has-bg-lightgrey">
    <h2 class="p-20">Beställ {{ title($route.path) }}</h2>
    <div class="wrapper">
      <div class="pb-10">
        <h3 class="pt-20">Bolaget som skall analyseras</h3>
        <form @submit.prevent="sendEmail" autocomplete="off">
          <div class="fontsize-6 has-text-left mt-10 has-color-darkgrey">Bolagsnamn<span v-if="this.analyserat_bolag === ''" class="has-color-secondary"> (obligatoriskt)</span></div>
          <input type="text" id="analyserat_bolag" v-model="analyserat_bolag" name="analyserat_bolag" placeholder="Bolagsnamn" required>
          <div class="fontsize-6 has-text-left mt-10 has-color-darkgrey">Org.nr.<span v-if="this.org_nr === ''" class="has-color-secondary"> (obligatoriskt)</span></div>
          <input title="Ange ett 10-siffrigt organisationsnummer" pattern="[0-9-]{10,11}" type="text" id="org_nr" v-model="org_nr" name="org_nr" placeholder="Org.nr." required>

        <h3 class="pt-20">Önskat leveransformat</h3>
        <div v-if="$route.path === '/bestall/kassaflode'">
          <label for="kassaflode_rapport" class="radio-button">
            <input type="radio" id="kassaflode_rapport" v-model="order" name="order" value="Kassaflöde - Rapport (4 500 kr)">
            <div class="radio-content">PDF-rapport (4 900 kr + moms)</div>
          </label>
        </div>

        <div v-else-if="$route.path === '/bestall/bokslut'">
          <label for="bokslut_rapport" class="radio-button">
            <input type="radio" id="bokslut_rapport" v-model="order" name="order" value="Bokslutsanalys - Rapport (3 500 kr)" required>
            <div class="radio-content">PDF-rapport (1 900 kr + moms)</div>
          </label>
        </div>

        <div v-else-if="$route.path === '/bestall/vardering'">
          <label for="vardering_rapport" class="radio-button">
            <input type="radio" id="vardering_rapport" v-model="order" name="order" value="Värderingsanalys - Rapport (4 500 kr)" required>
            <div class="radio-content">PDF-rapport (4 900 kr + moms)</div>
          </label>
        </div>

        <div v-else-if="$route.path === '/bestall/webbrapport'">
          <label for="webbrapport_kassaflode" class="radio-button">
            <input type="radio" id="webbrapport_kassaflode" v-model="order" name="order" value="Webbrapport Kassaflöde (3 900 kr)" required>
            <div class="radio-content">Webbrapport Kassaflödesgenomgång (3 900 kr + moms)</div>
          </label>
        </div>

        <p v-else>Saknas för tillfället</p>

        <h3 class="pt-20">Beställare</h3>
          <div class="fontsize-6 has-text-left mt-10 has-color-darkgrey">E-post<span v-if="this.bestallare_epost === ''" class="has-color-secondary"> (obligatoriskt)</span></div>
          <input type="email" id="bestallare_epost" v-model="bestallare_epost" name="bestallare_epost" placeholder="E-post" required>
          <div class="fontsize-6 has-text-left mt-10 has-color-darkgrey">Ev. meddelande (valfritt)</div>
          <textarea id="bestallare_kommmentar" v-model="bestallare_kommentar" name="bestallare_kommentar" rows="5"></textarea>
          <input type="submit" value="Beställ">
        </form>
      </div>

    </div>
  </div>
</template>

<script>
  import emailjs from 'emailjs-com';

  export default {
    name: 'ContactUs',
      data() {
        return {
          analyserat_bolag: '',
          org_nr: '',
          order: 'Webbrapport Kassaflöde (3 900 kr)',
          bestallare_epost: '',
          bestallare_kommentar: '',
        }
      },
    methods: {
      title(name) {
        switch (name) {
          case '/bestall/kassaflode':
            return 'Kassaflödesanalys';
          case '/bestall/vardering':
            return 'Värderingsanalys';
          case '/bestall/bokslut':
            return 'Bokslutsanalys';
          case '/bestall/webbrapport':
            return 'Webbrapport';
        }
      },
//      submit(){
//        this.$router.push({ path: '/bestallt' });
//      },
      sendEmail(e) {
        try {
          emailjs.sendForm('service_o5kaz08', 'template_visrpm8', e.target,
          'user_iUauCCtROIJrymoMzeAKu', {
            analyserat_bolag: this.analyserat_bolag,
            org_nr: this.org_nr,
            order: this.order,
            // bestallare_bolag: this.bestallare_bolag,
            // bestallare_orgnr: this.bestallare_orgnr,
            // bestallare_kontakt: this.bestallare_kontakt,
            bestallare_epost: this.bestallare_epost,
            bestallare_kommentar: this.bestallare_kommentar,
          })
        } catch(error) {
            console.log({error})
        }
        // Reset form field
        this.analyserat_bolag = ''
        this.org_nr = ''
        this.order = ''
        // this.bestallare_bolag = ''
        // this.bestallare_orgnr = ''
        // this.bestallare_kontakt = ''
        this.bestallare_epost = ''
        this.bestallare_kommentar = ''
        this.$router.push({ path: '/bestallt' });
      },
    },
    mounted () {
      window.scrollTo(0, 0)
    }
}
</script>

<style lang="scss" scoped>

.wrapper {
  max-width: 1000px;
  margin: auto;
}


form {
  width: 70%;
  margin: auto;
}

textarea, 
input {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}

input[type=submit] {
  position: relative;
  width: calc(100% + 4rem);
  padding: 1rem;
  margin-top: 2rem;
  left: -2rem;
  transition: 0.5s;
  border-radius: 0.2rem;
}

form:invalid input[type="submit"] {
  background: $lightgrey;
  color: $grey;
  border: 1px solid $grey;
  cursor: not-allowed;
}

form:valid input[type="submit"] {
  background: $secondary;
  color: $light;
  border: 1px solid $primary;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1rem 0 $darkgrey;
    background-color: lighten($secondary, 7.5%);
    color: $white;
    border-color: $dark;
  }
}

/** Radiobutton */

.radio-button > input {
  visibility: hidden;

  + div {
    width: 100%;
    margin: auto;
    cursor:pointer;
    border: 1px solid $grey;
    border-radius: 0.2rem;
    padding: 1rem;
    background-color: $light;
    transition: 0.5s;

    &:hover {
      box-shadow: 0 0 1rem 0 $darkgrey;
      border-color: $dark;
      background-color: $secondary;
      color: $white;
    }
  }

  // Den valda diven
  &:checked + div {
    background-color: $primary;
    border-color: $dark;
    color: $white;
  }
}

.button {
  margin: 2rem auto 1rem;
  width: 80%;
  border: 1px solid $primary;
  background-color: $secondary;
  color: $light;
  &:hover {
    background-color: lighten($secondary, 7.5%);
    color: $white;
  }
}

</style>