<template>
  <div class="is-flex is-column">

    <article>
      <div class="is-flex is-row has-align-items-start">
        <div class="has-width-three pr-10 has-text-left">
          <h2 class="mb-20">Webbrapport</h2>
          <p>Många förknippar en kassa&shy;flödes&shy;analys med den svår&shy;tydda tabell som större bolag måste inkludera i sin års&shy;redovisning.</p>
          <p>Men en kassaflödes&shy;analys kan också ligga till grund för en <span class="has-fontweight-600">djup&shy;lodande analys som skapar större förståelse för hur de olika delarna i verk&shy;samheten påverkar bolagets likvida medel</span>. Denna analys kan därefter vara ett utmärkt under&shy;lag för att planera och genom&shy;föra åtgärder för att förbättra verksamheten.</p>
          <p>Med den här kassaflödes&shy;genomgången, som presenteras i form av en webb&shy;rapport, tar vi läsaren genom hela processen från att beräkna de totala kassa&shy;flödena till att analysera hur de ingående delarna påverkat bolagets ekonomiska situation över tid.</p> 

          <h3>Användning</h3>
          <p>Vår kassaflödesgenomgång bör användas av bolaget internt eller för en genomgång tillsammans med sin företagsrådgivare/revisor.</p>

          <h3>Leveransformat</h3>
          <p>Analysen levereras som en omfattande webbrapport.</p>

          <h3>Pris</h3>
          <p>3 900 kr (+ moms)</p>
        </div>

        <div class="has-width-one info-box is-hidden-on-mobile">
          <div class="fontsize-3 has-fontweight-600">Exempel</div>
          <div class="pdf">
            <a href="https://demo.analyscenter.se/webreport/8d0cde89-ea2c-4608-a117-a71aff2ef73c" target="_blank" class="pdf">Webbrapport</a>
          </div>
        </div>
      </div>

    </article>

    <FooterOrder reportType="webbrapport"/>
    
  </div>
</template>

<script>
  import FooterOrder from "@/components/FooterOrder.vue";

	export default {
		name: "AnalysesWebreport",
		components: {
			FooterOrder,
    },
    mounted () {
      window.scrollTo(0, 0)
    }
	};

</script>

<style lang="scss" scoped>

article {
  margin-bottom: 15vh;

}
</style>