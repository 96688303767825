<template>
  <div class="is-flex is-column">

    <article>
      <div class="is-flex is-row has-align-items-start">
        <div class="has-width-three pr-10 has-text-left">
          <h2 class="mb-20">Boksluts&shy;analys</h2>
          <p>En bokslutsanalys inleds med vår populära bolagskompass som är ett pedagogiskt sätt att visualisera ett bolags resultat- och balansräkning. Därefter gås resultat- och balansräkningens poster stegvis igenom och jämförs med tidigare år.</p>
          <p>Den som så önskar kan välja att även inkludera en kassaflödesanalys i rapporten.</p>

          <h3>Användning</h3>
          <p>Vår kassaflödesrapport är ett lite mer formellt sätt att presentera bolagets kassaflöden och passar utmärkt för att visa upp för externa intressenter såsom banken eller investerare.</p>

          <h3>Leveransformat</h3>
          <p>Analysen levereras som en niosidig PDF-rapport.</p>

          <h3>Pris</h3>
          <p>1 900 kr (+ moms)</p>
        </div>

        <div class="has-width-one info-box is-hidden-on-mobile">
          <div class="fontsize-3 has-fontweight-600">Exempel</div>
          <div class="pdf">
            <a href="../exempel/Rapport_Bokslut.pdf" target="_blank" class="pdf">Rapport (PDF)</a>
          </div>
        </div>
      </div>

    </article>

    <FooterOrder reportType="bokslut"/>
    
  </div>
</template>

<script>
  import FooterOrder from "@/components/FooterOrder.vue";

	export default {
		name: "AnalysesYearend",
		components: {
			FooterOrder,
    },
    mounted () {
      window.scrollTo(0, 0)
    }
	};

</script>

<style lang="scss" scoped>

article {
  margin-bottom: 15vh;

}
</style>