<template>
  <div class="is-flex is-column">

    <article>
      <h2 class="mb-20">Vår analysprocess</h2>
      <div class="has-text-left">
        <p>Med egenutvecklad programvara och väl uttänkta mallar kan vår analytiker beräkna och sammanställa ekonomisk information snabbare än någon annan. Och om det går snabbt för oss innebär det i sin tur att det kostar betydligt mindre för dig att få tillgång till avancerade analyser.</p>
        <p>Analyspartner är en tjänst från Bolagskompassen AB som sedan 2013 har utvecklat programvara för analys och presentation av ekonomisk information. Vår programvara används av ledande revisions- och rådgivningsföretag för att förenkla sin rådgivning så att de istället för att skyffla siffror kan lägga tiden på rådgivning ihop med kunden.</p>
        <h3 class="mt-20">Steg 1: Du väljer analys</h3>
        <p>Det börjar med att du väljer en av våra standardanalyser som vi har skapat utifrån de vanligaste behoven som vi upplever att små och medelstora företag har. </p>
        <h3 class="mt-20">Steg 2: Programmmet kontrollerar</h3>
        <p>När du har valt vilken analys som önskas så kontrollerar vi via programvaran så att bolaget är lämpligt för den aktuella analysen. Skulle bolaget inte visa sig lämpligt så återkommer vi snarast med besked om att vi avbryter - och då sker naturligtvis ingen debitering.</p>
        <h3 class="mt-20">Steg 3: Vi analyserar</h3>
        <p>När kontrollen är gjord är det dags för vår erfarne analytiker med mer än tjugo års erfarenhet att titta på bolaget, göra justeringar och sammanställa resultatet.</p>
        <h3 class="mt-20">Steg 4: Leverans inom två arbetsdagar</h3>
        <p>Avslutningsvis levereras analysen enligt dina önskemål normalt inom två arbetsdagar. Vi erbjuder flera olika leveransformat såsom en mer formell rapport, en lite mer lättsam infografik och dessutom finns möjligheten för vissa analyser att få en inspelad genomgång där vår erfarne analytiker går igenom analysen.</p>
      </div>
    </article>
    
      <router-link to="/analyser" class="link has-color-white">
        <div class="button button-continue link">
            Se alla analyser
        </div>
      </router-link>

    
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  }

}
</script>

<style lang="scss" scoped>

.button-continue {
  border: 1px solid $darkgrey;
  margin: 0 auto 2rem;
  width: 35vw;
  background: $secondary;
  color: $light;
  font-weight: 600;

  &:hover {
    background: lighten($secondary, 7.5%);
    border-color: $black;
    color: $white;
  }
}


</style>