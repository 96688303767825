<template>
  <div class="is-flex is-column">

    <article>
      <div class="is-flex is-row has-align-items-start">
        <div class="has-width-three pr-10 has-text-left">
          <div class="is-hidden-on-mobile">
            <h2 class="mb-20">Kassaflödes&shy;analys</h2>
            <p>Med en kassaflödesanalys fås svaret på frågan "Vart tar pengarna vägen?" och är en analys som alla företagare borde göra årligen. </p>

            <h3>Beskrivning av kassaflöden</h3>
            <p>
              Kassaflöden för ett bolag motsvarar plånbokseffekten för
              privatpersoner d.v.s. beskriver vilka in- och utbetalningar som gjorts
              under en viss tidsperiod. Storleken på de totala kassaflödena kan
              enkelt beräknas genom att räkna ut förändringen för kassa & bank
              mellan två tidpunkter.
            </p>
            <p>
              Även om det totala kassaflödena kan ha visst intresse så är det av
              betydligt mer intressant om en mer detaljerad uppdelning av hur
              kassaflödena skett genomförs. Detta kallas vanligen för
              kassaflödes&shy;analys eller finansierings&shy;analys och är för
              större företag en obligatorisk del av årsredovisningen.
            </p>

            <h4>Uppdelning av kassaflöden</h4>
            <p>
              Vanligtvis sker det en uppdelning av kassaflödena i tre eller fyra
              delar separata delar. Denna uppdelning sker för att bättre förstå de
              behov som bolaget har samt hur det kan arbeta för att effektivisera
              dessa.
            </p>

            <ul>
              <li>Operativa kassaflöden</li>
              <li>Förändring av rörelsekapital</li>
              <li>Investeringar</li>
              <li>Finansiering</li>
            </ul>

            <h4>Stora bolag måste göra en kassaflödesanalys</h4>
            <p>
              Som tidigare nämnts måste vissa bolag ha med en kassaflödesanalys i
              årsredovisningen. Detta gäller för bolag som räknas som stora bolag.
              För att räknas som stort bolag måste minst två av nedastående tre
              kriterier uppfyllas för de två senaste räkenskapsåren.
            </p>

            <ul>
              <li>Fler än 50 anställda i genomsnitt under räkenskapsåret</li>
              <li>Mer än 40 miljoner kronor i balansomslutning</li>
              <li>Mer än 80 miljoner kronor i nettoomsättning</li>
            </ul>
            <p>
              Förutom bolag som uppfyller kriterierna ovan räknas alltid
              försäkringsföretag, värdepappersbolag, kreditinstitut och noterade
              bolag som stora bolag och måste således inkludera en kassaflödesanalys
              i årsredovisningen.
            </p>

            <h4>Alla bolag borde göra en kassaflödesanalys</h4>
            <p>
              Att ha koll på ett bolags betalningsströmmar är fundamentalt för att
              kunna driva ett företag effektivt. Genom att regelbundet, minst en
              gång om året, genomföra en kassaflödesanalys och gå igenom den med sin
              rådgivare kan ett bolag försäkra sig om att de använder sina resurser
              på ett effektivt sätt och är väl förberedda för framtiden.
            </p>
            <h3>Krav</h3>
            <p>För att kunna göra en kassaflödesanalys krävs att bolaget:</p>
            <ul>
              <li>är ett registrerat aktiebolag</li>
              <li>har minst tre officiella bokslut</li>
            </ul>
          </div>

        <div class="has-width-two has-text-left only-on-mobile">
          <h2>Gratis webbrapport</h2>
          <p>Webbrapporter är under utveckling och istället för att betatesta internt på slumpmässigt valda bolag så erbjuder vi <span class="has-fontweight-600">gratis kassaflödes&shy;analyser</span> till företagare och rådgivare.</p>
          <p>Erbjudandet gäller till siste maj 2022 och vi räknar med att kunna leverera analyserna inom 2-5 arbetsdagar.</p>
        </div>


        </div>

        <div class="has-width-two has-text-left info-box is-hidden-on-mobile">
          <div class="fontsize-3 has-fontweight-600">Gratis webbrapport</div>
          <p>Webbrapporter är under utveckling och istället för att betatesta internt på slumpmässigt valda bolag så erbjuder vi <span class="has-fontweight-600">gratis kassaflödes&shy;analyser</span> till företagare och rådgivare.</p>
          <p>Erbjudandet gäller till siste maj 2022 och vi räknar med att kunna leverera analyserna inom 2-5 arbetsdagar.</p>
        </div>

      </div>

    </article>

    <FooterOrder reportType="kassaflode"/>
    
  </div>
</template>

<script>
  import FooterOrder from "@/components/FooterOrder.vue";

	export default {
		name: "Webreport",
		components: {
			FooterOrder,
    },
    mounted () {
      window.scrollTo(0, 0)
    }
	};

</script>

<style lang="scss" scoped>

article {
  margin-bottom: 15vh;

}


</style>