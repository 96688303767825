<template>
  <div class="is-flex has-space-between" id="header">
    <div class="px-20 py-10 has-width-one">
      <router-link to="/" class="link">Analyspartner</router-link>
    </div>

    <div class="nav p-10 has-width-six hide-on-mobile">
      <router-link to="/" class="link">Hem</router-link>
      <router-link to="/sa-funkar-det" class="link">Så funkar det</router-link>
      <router-link to="/analyser" class="link">Analyser</router-link>
      <router-link to="/blogg" class="link is-hidden">Blogg</router-link>
    </div>

    <div class="nav p-10 has-width-one hide-on-mobile">
      <router-link to="/kontakt" class="link">Kontakta oss</router-link>
    </div>

  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

.nav * {
  padding: 0 1rem;
  margin: 0 1rem;
}

.nav .router-link-exact-active {
  border-bottom: 0.1rem solid $secondary;
}

#header {
  position: sticky;
  top: 0;
  background-color: $dark;
  color: $white;
  z-index: 1000;
}

@media screen and (max-width: 1000px) {
  .hide-on-mobile {
    display: none;
  }  
}


</style>